
import gql from "graphql-tag"
const GRAPHQL_PATH = "http://localhost:4000/graphql";



const GET_AVAILABILITIES = gql`

    query getAvailabilities($salesperson_ids:[String]!, $room_names:[String]!, $date: Timestamp)
    {
        getAvailabilities(salesperson_ids: $salesperson_ids, room_names: $room_names, date: $date){
            time
            salesPerson_id
        }
    }

`;
const BOOK = gql`
    mutation bookAppointment($start: Timestamp, $room_names: [String]!, $salesperson_id: String!,$name: String!, $phone: String!, $email: String, $message: String) {
        bookAppointment(start: $start, room_names: $room_names, salesperson_id: $salesperson_id, name: $name, phone: $phone,  email: $email, message: $message)
    }
`;

export{
    GRAPHQL_PATH,
    GET_AVAILABILITIES,
    BOOK
}
