import React, {useEffect, useState} from "react";
import moment from 'moment'
import 'moment-timezone';
import {Scrollbars} from 'react-custom-scrollbars';
import Card from '@material-ui/core/Card';
import {Mutation} from "react-apollo"
import './App.css';
import logo from './logo.png';
import neural_solutions_logo from './neural_solutions.png';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import TextField from "@material-ui/core/TextField";
import GetServices from "./getServices";
import Alert from '@material-ui/lab/Alert';
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_AVAILABILITIES, BOOK} from "./typedefs";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {withStyles} from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import RoomIcon from '@material-ui/icons/Room';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiPhoneNumber from "material-ui-phone-number";
import Checkbox from '@material-ui/core/Checkbox';
import validator from "validator";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import CustomizedSteppers from './CustomizedSteppers';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PersonIcon from '@mui/icons-material/Person';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import OutlinedInput from '@mui/material/OutlinedInput';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { lime } from "@material-ui/core/colors";

import {
    OutlinedInput as MuiOutlinedInput,
} from "@material-ui/core";
import GetAllRoomsAndSalesPersons from "./graphql/getAllRoomsAndSalesPersons";
import ReactPixel from 'react-facebook-pixel';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

function StepOne(props) {

    const data = props.data
    let duration = props.duration
    let setDuration = props.setDuration
    let rooms = props.rooms
    let setRooms = props.setRooms
    let salesPersons = props.salesPersons
    let setSalesPersons = props.setSalesPersons


    const useStyles = makeStyles({
        root: {
            "& .MuiOutlinedInput-input": {
                color: "grey",
                borderRadius: "0px"
            },
            "& .MuiInputLabel-root": {
                color: "grey",
                borderRadius: "0px"
            },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey",
                borderRadius: "0px"
            },
            "&:hover .MuiOutlinedInput-input": {
                color: "grey",
                borderRadius: "0px"
            },
            "&:hover .MuiInputLabel-root": {
                color: "grey",
                borderRadius: "0px"
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey",
                borderRadius: "0px"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                color: "#101616",
                borderRadius: "0px"
            },
            "& .MuiInputLabel-root.Mui-focused": {
                color: "#101616",
                borderRadius: "0px"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#101616",
                borderRadius: "0px"
            }
        }
    });


    const classes = useStyles();

    const handleRoomsChange = (event) => {
        setRooms(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value,);

        var duration_new = 0
        for (var i=0; i<event.target.value.length; i++)
        {
            const durationCurr = (data['rooms'].filter(obj => {
                return obj.name === event.target.value[i]
            }))[0].duration

            duration_new = duration_new + durationCurr

        }
        setDuration(duration_new)

    };

    const handleSalesPersonsChange = (event) => {
        console.log(event)
        setSalesPersons(event.target.value);
    };

    const shouldDisableCheckbox = value => {
        const maxAllowed = 1
        console.log(rooms.length, maxAllowed, rooms.indexOf(value), value)
        return rooms.length >= maxAllowed && rooms.indexOf(value) === -1
    }
    return (
        <div className="step_one">
            <FormControl variant="outlined" style={{width: "500px", maxWidth: "65vw", marginBottom: "30px"}}>
                <TextField
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    label="Select Room to Design"
                    required
                    variant="outlined"
                    select
                    className={classes.root}
                    SelectProps={{
                        multiple: true,
                        value: rooms,
                        renderValue: (selected) => selected.join(', '),
                        onChange: handleRoomsChange
                    }}
                >
                    {data['rooms'] && (data['rooms']).map(row => {
                        console.log(row)
                        return (<MenuItem key={row.name} value={(row).name} disabled={shouldDisableCheckbox((row).name)}>
                            <Checkbox checked={rooms.indexOf((row).name) > -1} style={{color: "#101616"}}
                                      />
                            <ListItemText primary={(row).name}/>
                        </MenuItem>)
                    })}
                </TextField>

            </FormControl>

            <div className="break"/>

            <FormControl variant="outlined" style={{width: "500px", maxWidth: "65vw", marginBottom: "30px"}}>
                <TextField
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={salesPersons}
                    onChange={(e) => handleSalesPersonsChange(e)}
                    label="Select Design & Sales Consultants"
                    required
                    className={classes.root}
                    variant="outlined"
                    select
                >
                    {
                        data['salesPersons'] &&
                        <MenuItem value={(data['salesPersons'])}>Any</MenuItem>
                    }

                    {data['salesPersons'] && (data['salesPersons']).map(row => {
                        console.log()
                        return(
                            <MenuItem key={(row.email)} value={(row).email}>{(row).name}</MenuItem>
                        )
                    })}

                </TextField>
            </FormControl>
        </div>
    )
}

function StepTwo(props) {
    const [getAvailabilities, {data, error, loading}] = useLazyQuery(GET_AVAILABILITIES)
    const handleTimeChange = (time) => {
        props.setSalesPersons(data['getAvailabilities'].filter(obj => {
            return obj.time === time
        })[0].salesPerson_id)
        props.setTime(time)
        console.log({time})
    }

    console.log(props)

    const handleDateChange = (event) => {
        //refresh time
        props.setTime('');
        let toSet = new Date(event)
        toSet = toSet.setUTCHours(0,0,0)
        props.changeDate(toSet);
        console.log(event)
        console.log(toSet)
        let salesperson_ids =  props.salesPersons
        if(Array.isArray(salesperson_ids)){
            salesperson_ids = props.salesPersons.map(x=>x.email)
        }
        getAvailabilities({
            variables: {
                salesperson_ids: salesperson_ids,
                room_names: props.rooms,
                date: new Date(event.setUTCHours(0,0,0))
            }
        })
    };

    useEffect(() => {
        let salesperson_ids =  props.salesPersons
        if(Array.isArray(salesperson_ids)){
            salesperson_ids = props.salesPersons.map(x=>x.email)
        }
        console.log("IN USE EFFECT")
        getAvailabilities({
            variables: {
                salesperson_ids: salesperson_ids,
                room_names: props.rooms,
                date: new Date(new Date(props.date).setUTCHours(0,0,0))
            }
        })
    }, [])

    const StyledListItem = withStyles({
        root: {
            "&.Mui-selected": {
                color: "#fff",
                backgroundColor: "#3f51b5",
            },
            "&.Mui-selected:hover": {
                color: "#fff",
                backgroundColor: "#3f51b5",
            }
        }
    })(ListItem);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 305,
        },
        listSection: {
            backgroundColor: 'inherit',
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0,
        }
    }));

    const defaultMaterialTheme = createMuiTheme({
        palette: {
            primary: {
                main: "#101616"
            },
        },
    });

    const classes = useStyles();

    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    const dateIn3Days = new Date();
    dateIn3Days.setDate(dateIn3Days.getDate() + 3);

    return (
        <div className="step_two">
            <div className="calendar">
                <Card>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <MuiThemeProvider theme={defaultMaterialTheme}>
                            <DatePicker
                                autoOk
                                orientation="landscape"
                                disablePast
                                disableToolbar
                                variant="static"
                                openTo="date"
                                value={props.date}
                                maxDate={aYearFromNow}
                                onChange={handleDateChange}
                            />
                        </MuiThemeProvider>
                    </MuiPickersUtilsProvider>
                </Card>
            </div>
            <div className="time">
                <Card style={{height: "100%"}}>
                    <FormControl variant="outlined" style={{width: "100%", height: "100%"}}>
                        <List className={classes.root} subheader={<li/>} style={{width: "100%", height: "100%"}}>
                            <Scrollbars>
                                {
                                    data == null  &&
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        padding: "40%"
                                    }}>
                                        <Loader
                                            type="Oval"
                                            color="#333333"
                                            height='100%'
                                            width='100%'
                                            timeout={30000} //30 secs
                                        />
                                    </div>
                                }
                                {

                                    data && (new Date(props.date).setUTCHours(0,0,0) < new Date(dateIn3Days).setUTCHours(0, 0, 0, 0)) &&
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        padding: "20px"
                                    }}>
                                        <h3 style={{color: "rgba(0, 0, 0, 0.9)", fontSize: "16px"}}>
                                            For bookings within the next 3 days please call: +356 2124 0100
                                        </h3>
                                    </div>
                                }
                                {
                                    ((data && (new Date(props.date).setUTCHours(0, 0, 0, 0) >= new Date(dateIn3Days).setUTCHours(0, 0, 0, 0)) && data['getAvailabilities'].length < 1) || error) &&
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        padding: "20px"
                                    }}>
                                        <h3 style={{color: "rgba(0, 0, 0, 0.9)", fontSize: "16px"}}>
                                            No times available. Please select a different date.
                                        </h3>
                                    </div>

                                }
                                {
                                    data && (new Date(props.date).setUTCHours(0, 0, 0, 0) !== new Date().setUTCHours(0, 0, 0, 0)) && (data['getAvailabilities']).map(function (sectionId) {
                                        console.log(sectionId)
                                        return <li key={`section-${sectionId.time}`} className={classes.listSection}>
                                            <ul className={classes.ul}>
                                                <StyledListItem button selected={sectionId.time === props.time}
                                                                onClick={() => handleTimeChange(sectionId.time)}
                                                                key={`item-${sectionId}-${sectionId}`}>
                                                    <ListItemText
                                                        primary={(new Date(sectionId.time).toLocaleString('en-GB', {
                                                            timeZone: 'Europe/Malta',
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        }))}/>
                                                </StyledListItem>
                                            </ul>
                                        </li>
                                    })
                                }
                            </Scrollbars>
                        </List>
                    </FormControl>
                </Card>
            </div>
        </div>
    );
}

function StepThree(props) {

    return (
        <div className="step_three">
            <div className="details_div">
                <TextField id="outlined-basic" label="Full Name" variant="outlined" value={props.name} onChange={(e) => {
                    props.setName(e.target.value)
                }} style={{width: "100%"}} inputProps={{
                    autocomplete: 'off',
                    maxLength: 30
                }} required/>
            </div>
            <div className="break"/>
            <div className="details_div">
                <MuiPhoneNumber
                    inputProps={{
                        autocomplete: 'off',
                    }}
                    name="phone"
                    label="Mobile Number"
                    data-cy="user-phone"
                    regions={'europe'}
                    defaultCountry={'mt'}
                    disableAreaCodes = {true}
                    value={props.phoneNumber}
                    onChange={(e) => props.setPhoneNumber(e)}
                    variant="outlined"
                    countryCodeEditable={false}
                    style={{width: "100%"}}
                    error={(props.phoneNumber.startsWith("+356")) && (props.phoneNumber.length !== 12)}
                    required
                />
            </div>
            <div className="break"/>
            <div className="details_div">
                <TextField id="outlined-basic" label="Email" variant="outlined" error={(props.email !== '') && (!validator.isEmail(props.email))} onChange={(e) => {
                    props.setEmail(e.target.value)
                }} style={{width: "100%"}} inputProps={{
                    autocomplete: 'off',
                    maxLength: 254
                }}/>
            </div>
            <div className="break"/>
            <div className="details_div">
                <TextField id="outlined-multiline-static" label="Message (Optional)" multiline variant="outlined" onChange={(e) => {
                    props.setMessage(e.target.value)
                }} style={{width: "100%"}} inputProps={{
                    autocomplete: 'off',
                    maxLength: 254
                }}/>
            </div>
        </div>
    )

}

function GetStepContent(data, date, changeDate, time, setTime, name, setName, email, setEmail, phoneNumber, setPhoneNumber, rooms, setRooms, salesPersons, setSalesPersons, duration, setDuration, firstTime, setFirstTime, step, message, setMessage) {

    switch (step) {
        case 0:
            return <StepOne data={data} rooms={rooms} setRooms={setRooms} salesPersons={salesPersons} setSalesPersons={setSalesPersons}
                            duration={duration} setDuration={setDuration}/>
        case 1:
            return <StepTwo date={date} changeDate={changeDate} time={time} setTime={setTime} rooms={rooms}
                            setRooms={setRooms} salesPersons={salesPersons} setSalesPersons={setSalesPersons} duration={duration}/>;
        case 2:
            return <StepThree name={name} setName={setName} email={email} setEmail={setEmail} phoneNumber={phoneNumber}
                              setPhoneNumber={setPhoneNumber} firstTime={firstTime} setFirstTime={setFirstTime} setMessage={setMessage} message={message}/>;
        default:
            return 'Error: Unknown Step';
    }
}

function Body() {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 3)
    //
    // const data = {"rooms":
    //         [
    //             {"room":{"id":16,"name":"Bedroom","duration":45}},
    //             {"room":{"id":17,"name":"Bathroom","duration":30}},
    //             {"room":{"id":18,"name":"Living Room","duration":15}},
    //         ],
    //     "salesPersonss":
    //         [
    //             {"salesPersons":{"id":11,"name":"Mr Matthew Galea"}},
    //             {"salesPersons":{"id":12,"name":"Mr Kurt Abela"}},
    //             {"salesPersons":{"id":33,"name":"Ms Francesca Barbara"}},
    //         ]
    // }
    const data = GetAllRoomsAndSalesPersons()
    const [date, changeDate] = useState(tomorrow);
    const [time, setTime] = React.useState('');
    const [rooms, setRooms] = React.useState([]);
    const [salesPersons, setSalesPersons] = React.useState('');
    const [duration, setDuration] = React.useState(-1);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['ROOM', 'DATE & TIME', 'DETAILS'];
    const [error, setError] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const [firstTime, setFirstTime] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    useEffect(()=>{
        if(data?.salesPersons != null && data?.salesPersons.length > 0){
            function compare( a, b ) {
                if ( a.name < b.name ){
                    return -1;
                }
                if ( a.name > b.name ){
                    return 1;
                }
                return 0;
            }

            data.salesPersons.sort( compare );
        }

    }, [data])

    const useStyles = makeStyles((theme) => {
        return {
            root: {
                "&.MuiButton-root": {
                    borderRadius: "0px"
                },
                "&.MuiButton-contained": {
                    backgroundColor: "#101616",
                    border: "1px solid white",
                    color: "white"
                },
                "&.Mui-disabled": {
                    color: "rgba(0,0,0,0.26)",
                    backgroundColor: "transparent",
                    border: "0px",
                    boxShadow: 'none',
                },
            }
        };
    });

    const classes = useStyles();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    if(data === "LOADING"){
        return(
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh"
            }}>
                <Loader
                    type="Oval"
                    color="#333333"
                    timeout={30000} //30 secs
                />
            </div>
        )
    }else {
        return (
            <div className="body_main">
                <div className="stepper">
                    <CustomizedSteppers className="stepper" activeStep={activeStep}/>
                </div>
                <div className="mainContent">
                    <div style={{flexGrow:"1"}}></div>
                    <div className="mainContent_inner">
                        {((typeof data === 'string' || data instanceof String) && data !== 'LOADING') &&
                        <Dialog
                            open={open}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Service is currently down"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">{data}</DialogContentText>
                            </DialogContent>
                        </Dialog>
                        }
                        {error !== '' &&
                        <Dialog
                            open={open}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"There was a problem with your request"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">{error}</DialogContentText>
                            </DialogContent>
                        </Dialog>
                        }
                        {activeStep === steps.length ? (
                            <div style={{display: "flex", justifyContent: "center", marginTop: "30px"}}>
                                <div style={{width: "800px", maxWidth: "80vw",}}>
                                    <Alert severity="success">
                                        <p style={{margin: "0"}}><b>Your booking is pending confirmation. You will receive a confirmation soon.</b></p>
                                    </Alert>
                                    <div className="details_confirmation">
                                        <div style={{textAlign: "left", flexGrow: "1", padding: "20px 20px 6px 20px"}}>
                                            <h5 style={{margin: "0", fontSize: "18px"}}>Pending Appointment Details</h5>
                                            <p className="details_p">Rooms To View: {
                                                ((data['rooms'].filter(obj => {return rooms.includes(obj.name)})).map(x=>x.name)).join(", ")
                                            }
                                            </p>

                                            <p className="details_p">Sales Person: {(data['salesPersons'].filter(obj => {
                                                return obj.email === salesPersons
                                            }))[0].name}</p>
                                            <p className="details_p">Date: {moment(time).tz("Europe/Malta").format("ddd DD MMM") + " at " + moment(time).tz("Europe/Malta").format("HH:mm")}</p>
                                        </div>
                                        <div style={{textAlign: "left", flexGrow: "1", padding: "20px 20px 6px 20px"}}>
                                            <h5 style={{margin: "0", fontSize: "18px"}}>Personal Details</h5>
                                            <p className="details_p">Name: {name}</p>
                                            <p className="details_p">Phone: {phoneNumber}</p>
                                            {email !== '' &&
                                            <p className="details_p">Email: {email}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Typography component={'span'}
                                            className={classes.instructions}>{GetStepContent(data, date, changeDate, time, setTime, name, setName, email, setEmail, phoneNumber, setPhoneNumber, rooms, setRooms, salesPersons, setSalesPersons, duration, setDuration, firstTime, setFirstTime, activeStep, message, setMessage)}
                                </Typography>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                    >
                                        Back
                                    </Button>

                                    <Mutation mutation={BOOK}>
                                        {(bookAppointment, {loading}) => {
                                            return (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.root}
                                                    disabled=
                                                        {
                                                            (activeStep === 0 && (rooms.length === 0 || salesPersons === ''))
                                                            ||
                                                            (activeStep === 1 && (time === ''))
                                                            ||
                                                            (activeStep === 2 && (name === '' || (phoneNumber === '' || ((phoneNumber.startsWith("+356")) && (phoneNumber.length !== 12))) || (email !== '' && !validator.isEmail(email))))
                                                            ||
                                                            (loading)
                                                        }
                                                    onClick=
                                                        {() => {
                                                            if (activeStep === steps.length - 1) {
                                                                bookAppointment({
                                                                    variables: {
                                                                        start: time,
                                                                        room_names: rooms,
                                                                        salesperson_id: salesPersons,
                                                                        name: name,
                                                                        phone: phoneNumber,
                                                                        email: email,
                                                                        message: message
                                                                    }
                                                                }).then((result) => {
                                                                    console.log(result)
                                                                    ReactPixel.trackCustom("BookingMade", {});
                                                                    if (result.data.bookAppointment)
                                                                        handleNext()
                                                                    else
                                                                        setError("An error has occurred and the appointment has not been booked. Please call +356 2124 0100 to confirm your booking.")

                                                                    //window.location.reload()

                                                                }).catch(err => {

                                                                    // if (err.networkError) {
                                                                    //     setError('The server is currently down temporarily. Please try again later and contact the system administrators if you still encounter this issue. You can book via phone on +356 2124 0100');
                                                                    // } else {
                                                                    //     err.graphQLErrors.forEach(err => {
                                                                    //         setError(err.message);
                                                                    //     });
                                                                    // }

                                                                    console.error(err)
                                                                    setError('Service is temporarily unavailable. We apologize for any inconvenience caused. You can book via phone on +356 2124 0100');
                                                                })
                                                            } else {
                                                                handleNext()
                                                            }
                                                        }

                                                        }>
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                            )
                                        }}
                                    </Mutation>
                                </div>
                            </div>
                        )}
                    </div>
                    <div style={{flexGrow:"2"}}></div>
                </div>

            </div>
        );
    }
}

function App() {
    ReactPixel.init('1107778810356047', {}, {debug:true});
    ReactPixel.pageView(); // For tracking page view
    return (
        <div className="App">
            <div className="header">
                <div className="header_flex">
                    <div className="header_left">
                        <a className="logo_align" href='https://www.dinofino.com/'>
                            <img src={logo}/>
                        </a>
                        <div className="booking_portal">
                            <h1 style={{marginLeft: "10px", fontSize: "20px"}}>APPOINTMENT BOOKING</h1>
                        </div>
                    </div>
                    <div className="call">
                        <div className="call_inner">
                            <CallIcon style={{fontSize: 26}}/>
                            <h3 style={{marginLeft: "15px"}}>2124 0100</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="body">
                <div className="body_content">
                    <Body/>
                </div>
            </div>

            <footer>
                <div className="copyright">
                    <h6>© 2022 Dino Fino Operations Ltd. </h6>
                </div>
                <div className="footer_menu">
                    <a className="logo_align" href="https://www.neuralai.mt/" target="_blank">
                        <h6 style={{paddingRight: "5px"}} >DEVELOPED BY:
                            Neural AI - Data, Software and AI Services in Malta</h6>

                        <img style={{maxHeight: "40px"}} src={neural_solutions_logo}/>
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default App;
