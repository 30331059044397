import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';

const Get_AllRoomsAndSalesPersons = gql`

    query getAllRoomsAndSalesPersons{
        getAllRoomsAndSalesPersons{
            rooms{
                id
                name
                duration
            }
            salesPersons{
                email
                name
            }
        }
    }`;

function GetAllRoomsAndSalesPersons() {
    //executing the query
    const {loading, data, error} = useQuery(Get_AllRoomsAndSalesPersons)

    if (loading) {
        return "LOADING";
    }

    if (error) {
        return 'Service is temporarily unavailable. We apologize for any inconvenience caused.';
    }
    return data.getAllRoomsAndSalesPersons;
}

export default GetAllRoomsAndSalesPersons;
